import moment from 'moment'
import Resizer from "react-image-file-resizer"

export function diaDaSemana(d){
  d = parseInt(d)
  switch(d){
    case 1: return 'Domingo'
    case 2: return 'Segunda-feira'
    case 3: return 'Terça-feira'
    case 4: return 'Quarta-feira'
    case 5: return 'Quinta-feira'
    case 6: return 'Sexta-feira'
    case 7: return 'Sábado'
    default: return false
  }
}

export const squish = (text) => {
  return text
    .trim() // Remove espaços em branco no início e no final da string
    .replace(/\s+/, ' '); // Substitui todos os espaços em branco (tab, newline, espaço duplo, etc.) por um único espaço
}

export function capitalize(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}


export function isPhoneValid(phone) {
  if(!phone && phone !== 0) return false

  const phoneLength = String(phone).replace(/\D/g, '').length;
  return [12, 13].includes(phoneLength);
}


export function formatTel(numero = null) {
  if (numero === null) return ''

  const numeroLimpo = String(numero).replace(/\D/g, '').substr(0, 13)

  if (numeroLimpo.length !== 12 && numeroLimpo.length !== 13) {
    return numero
  }

  const codigoPais = numeroLimpo.slice(0, 2)

  const ddd = numeroLimpo.slice(2, 4)
  const numeroLocal = numeroLimpo.slice(4)

  let telefoneFormatado = `+${codigoPais} (${ddd}) `

  if (numeroLimpo.length === 11) {
    telefoneFormatado += `${numeroLocal.slice(0, 4)}-${numeroLocal.slice(4)}`
  } else {
    telefoneFormatado += `${numeroLocal.slice(0, 5)}-${numeroLocal.slice(5)}`
  }

  return telefoneFormatado
}

export function formatCpf(numero = null) {
  //if (numero === null) return ''
  const cpf = String(numero || '').replace(/[^0-9]/g, '').substr(0, 11)
  if (cpf.length !== 11) return cpf
  return cpf.substr(0, 3) + '.' + cpf.substr(3, 3) + '.' + cpf.substr(6, 3) + '-' + cpf.substr(9, 2)
}

export function formatCnpj(numero = '') {
  if (numero?.length < 14) return numero
  return numero?.substr(0, 2) + '.' + numero?.substr(2, 3) + '.' + numero?.substr(5, 3) + '/' + numero?.substr(8, 4) + '-' + numero?.substr(12, 2)
}

export const formatCpfCnpj = (value) => {
  if (!value) return 'N/A';
  const cleanedValue = String(value).replace(/\D/g, '');
  return cleanedValue.length === 11 ? formatCpf(cleanedValue) : formatCnpj(cleanedValue);
};

// Função para criar um objeto moment baseado no tipo de entrada
function dateToMoment(date, inputFormat, debug = false) {
  if (date === undefined) return moment();
  if (date instanceof Date) return moment(date);
  if (typeof date === 'string') return moment(date, inputFormat);
  return null; // Caso não seja nenhum tipo esperado
}

// Função principal para formatar a data
export function formatDate(initialDate, options = {}) {
  const {
    input = 'YYYY-MM-DDTHH:mm:ssZ',
    output = 'DD/MM/YYYY HH:mm:ss',
    default: defaultMessage = 'Data inválida'
  } = options;

  const date = dateToMoment(initialDate, input);

  if (!date || !date.isValid()) return defaultMessage;

  return date.format(output);
}

//VD-102489 por Hugo em 06/05/2024
export function formatarData(data = '') {
  const dataf = data.split('-');
  return dataf[2] + '/' + dataf[1] + '/' + dataf[0];
}

export function formatDateExtension(data = '') {
  moment.locale('pt-br')
  return moment(data).format('D [de] MMMM [de] YYYY [às] HH:mm:ss')
}

export function formatNumber(number, digits = 0) {
  const valor = parseFloat(number ?? 0)

  return valor.toLocaleString( "pt-BR", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  })
}

export function formatCEP(cep = '', force0 = false) {
  cep = String(cep).replace(/\D/g, '')
  cep = force0 ? '0'.repeat(8 - cep.length) + cep : cep

  return cep.replace(/(\d{5})(\d{3})/, '$1-$2')
}

export function strNums(numero) {
  return String(numero).replace(/[^0-9]/g, '')
}

export function toFloat(numero, casasdecimais = 0) {
  numero = String(numero)
  // const posComma = numero.indexOf(',')
  // const casasdecimais = numero.length - (posComma>=0?(posComma + 1):numero.length)
  return parseInt(numero.replace(/[^0-9]/g, '') || 0) / (10 ** casasdecimais)
}

export function getFileString(file) {
  return new Promise(function (resolve, reject) {
    let reader = new FileReader()
    reader.onload = function () { resolve(reader.result) }
    reader.onerror = reject
    //reader.readAsBinaryString(file)
    reader.readAsText(file, 'UTF-8')
  })
}

export function formatMoney(number, digits = 0) {
  const valor = parseFloat(number ?? 0)

  return valor.toLocaleString( "pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  })
}

export function convertUnit(valor, unidade = 'KG', converter = 'TON') {
  if (unidade === 'KG' && converter === 'TON') return (valor / 1000).toFixed(2);

  if (unidade === 'TON' && converter === 'KG')  return (valor * 1000).toFixed(2);

  return valor;
}

export function formatCurrency(data, casasdecimais = 0) {
  data = parseFloat('0' + String(data).replace(/[^0-9.]/g, '')) / 10 ** casasdecimais
  return new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(data)
}

export function fileToBase64(file) {
  return new Promise(function (resolve, reject) {
    //if( !file ) resolve('')
    var reader = new FileReader()
    reader.onload = function () { resolve(btoa(reader.result)) }
    reader.readAsBinaryString(file)
  })
}

export function readFile(file) {
  return new Promise(function (resolve, reject) {
    let reader = new FileReader()
    reader.onload = function () { resolve(reader.result) }
    reader.readAsDataURL(file)
  })
}

export const UFs = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
  { value: 'AR', label: 'Argentina' },
  { value: 'PY', label: 'Paraguay' },
]

export function validateCNH(cnh = '') {
  let char1 = cnh.charAt(0)
  if (cnh.replace(/[^\d]/g, '').length !== 11 || char1.repeat(11) === cnh) return false

  for (var i = 0, j = 9, v = 0; i < 9; ++i, --j) v += +(cnh.charAt(i) * j)

  var dsc = 0, vl1 = v % 11

  if (vl1 >= 10) {
    vl1 = 0; dsc = 2;
  }

  for (i = 0, j = 1, v = 0; i < 9; ++i, ++j) v += +(cnh.charAt(i) * j)

  let x = v % 11;
  let vl2 = (x >= 10) ? 0 : x - dsc;

  return ('' + vl1 + vl2) === cnh.substr(-2);
}

export function validateCPF(cpf = '') {
  if( !cpf ) return false

  cpf = cpf.toString().replace(/[^\d]+/g, '') // Remove caracteres não numéricos

  // CPF inválido se não tiver 11 dígitos ou todos os dígitos forem iguais
  if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) return false

  let soma = 0
  for (let i = 0; i < 9; i++)  soma += parseInt(cpf.charAt(i)) * (10 - i)

  let resto = (soma * 10) % 11
  if (resto === 10 || resto === 11) resto = 0

  // Primeiro dígito verificador não coincide
  if (resto !== parseInt(cpf.charAt(9))) return false

  soma = 0
  for (let i = 0; i < 10; i++) soma += parseInt(cpf.charAt(i)) * (11 - i)

  resto = (soma * 10) % 11
  if (resto === 10 || resto === 11) resto = 0

  // Segundo dígito verificador não coincide
  if (resto !== parseInt(cpf.charAt(10))) return false

  return true; // CPF válido
}

export function validateCNPJ(cnpj = '') {
  cnpj = cnpj.replace(/\D/g, '');

  if (cnpj.length !== 14) return false

  // Verifica se todos os dígitos são iguais (caso contrário, não é um CNPJ válido)
  if (/^(\d)\1+$/.test(cnpj)) return false

  // Calcula o primeiro dígito verificador
  let soma = 0, peso = 2;

  for (let i = 11; i >= 0; i--) {
    soma += parseInt(cnpj.charAt(i)) * peso;
    peso = peso === 9 ? 2 : peso + 1;
  }

  let digitoVerificador = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  // Verifica o primeiro dígito verificador
  if (parseInt(cnpj.charAt(12)) !== digitoVerificador) return false

  // Calcula o segundo dígito verificador
  soma = 0; peso = 2;

  for (let i = 12; i >= 0; i--) {
    soma += parseInt(cnpj.charAt(i)) * peso;
    peso = peso === 9 ? 2 : peso + 1;
  }

  digitoVerificador = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  // Verifica o segundo dígito verificador
  if (parseInt(cnpj.charAt(13)) !== digitoVerificador) return false

  return true
}

export function validateEmail(email = '') {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function validateCEP(cep = '') {
  return /^\d{5}-?\d{3}$/.test(cep)
}

export function isDate(strDt, format = 'DD/MM/YYYY') {
  return !isNaN(new Date(moment(strDt, format, true)))
}

export function toDateType(strDt, format = 'DD/MM/YYYY') {
  return moment(strDt, format, true)
}

export function validatePlaca(pais, placa) {
  if (placa == null || placa === "") {
    return false
  }

  if (pais === "Brasil" || pais === "Uruguai") {
    let regexT;
    if (placa.length === 7) { // Padrao mercosul ABC1D12 ou antigas AAA1324
      regexT = /^[A-Z]{3}[0-9][A-Z][0-9]{2}|[A-Z]{3}[0-9]{4}$/
    } else {
      return false
    }
    return regexT.test(placa)
  }

  else if (pais === "Argentina") {
    let regexT;
    if (placa.length === 7) { // Padrao mercosul
      regexT = /^[A-Z]{3}[0-9][A-Z][0-9]{2}$/
    } else if (placa.length === 6) { // padrao argentino de 1994 ate 2016
      regexT = /^[A-Z]{3}[0-9]{3}|[A-Z]{4}[0-9]{3}$/
    }
    else {
      return false
    }
    return regexT.test(placa)
  }

  else if (pais === "Chile") {
    let regexT;
    if (placa.length === 6) { // Padrao AB CD 12 OU AB 12 34
      //regexT = /^[A-HJ-NP-Z]{4}[0-9]{2}|[A-HJ-NP-Z]{2}[0-9]{4}$/
      regexT = /^[A-Z]{4}[0-9]{2}|[A-Z]{2}[0-9]{4}$/
    }
    else {
      return false
    }
    return regexT.test(placa)
  }

  else if (pais === "Paraguai") {
    let regexT;
    if (placa.length === 6) { // Padrao ABC OU MERCOSUL
      regexT = /^[A-Z]{3}[0-9]{3}$/
    }
    else if (placa.length === 7) {
      regexT = /^[A-Z]{3}[0-9A-Z][0-9A-Z][0-9]{2}$/
    }
    else {
      return false
    }
    return regexT.test(placa)
  }

  else {
    return false
  }

}

export function validateVencCNH(strDt) { // 2023-07-17
  const today = new Date();
  const expireDate = new Date(strDt);

  if (expireDate <= today) {
    return false
  } else {
    return true
  }
}

export function groupContrato(data, filter, soma, tab_name) {
  const groupedData = data.reduce((result, current) => {
    const campo_soma = parseFloat(current[soma]);

    const key = filter.map(prop => current[prop]).join('-');

    if (!result[key]) {
      result[key] = { ...current, [soma]: 0 };
      // result[key][soma] = 0
    }

    result[key][soma] += campo_soma;
    return result;
  }, {});

  function roundToDecimal(number, decimalPlaces) {
    const factor = 10 ** decimalPlaces;
    return Math.round(number * factor) / factor;
  }

  const finalArray = Object.values(groupedData);

  const roundedArray = finalArray.map((obj) => ({
    ...obj,
    [soma]: roundToDecimal(obj[soma], 2), // Rounding to 2 decimal places
  }));

  return { DS_STS: tab_name, LST_VCL: roundedArray }
}

export const validateQuantidade = (quantidade) =>
  !isNaN(quantidade) ? Number(quantidade) > 0 : false

export function checkArrayType(arr, col) {
  const types = new Set(arr.map(item => typeof item[col]));

  if (types.size === 1) {
    // If there's only one type, return it
    const type = isDate(arr[0][col]) ? 'date' : !isNaN(arr[0][col]) ? 'number' : typeof arr[0][col]
    return type
    // return [...types][0];
  } else {
    // If there are multiple types, return 0
    return 0;
  }
}

export function formatTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMins = String(mins).padStart(2, '0');

  return `${formattedHours}:${formattedMins}`;
}

export function unidadeVerify(unidades) {
  const unidades_ativas = unidades?.filter((unidade) => (unidade.CD_STS === '1' || unidade.CD_STS === null)); // Verifica quais unidades tem status como ativo ou null
  if (unidades_ativas.length === 0) { // Se nao tiver unidade ativa
    return "Sem unidades cadastradas";
  }
  else if (unidades_ativas.length === 1) {
    return unidades_ativas[0].LABEL;
  } else {
    // return (
    //   <div style={{display: 'inline',
    //     maxWidth:'50ch',
    //     textOverflow: 'ellipsis',
    //     whiteSpace: 'nowrap',
    //     overflow: 'hidden'}}
    //   >
    //     <a onClick={() => setUnidade(d)} style={{cursor:'pointer'}}>{unidades.map(org => org.LABEL).join(', ')}</a>
    //   </div>
    // )
    return null;
  }
}

export async function sendAppApiNovoAgendamento({ cota, motorista, tracao, prtIdUocc, cnpjTrp, App }) {
  const enderecoOrigem = cota.NM_LGDORI + ', ' + cota.NR_LGDORI + ', ' + cota.NM_LOCORI + ',' + cota.ID_UFORI + ',' + cota.NM_PAISORI
  const enderecoOrigemSemLGD = cota.NM_LOCORI + ',' + cota.ID_UFORI + ',' + cota.NM_PAISORI
  const coordOrigem =
    (await (await fetch(`https://nominatim.openstreetmap.org/search?q=${enderecoOrigem}&format=geojson`)).json()).features[0]?.geometry?.coordinates
    ?? (await (await fetch(`https://nominatim.openstreetmap.org/search?q=${enderecoOrigemSemLGD}&format=geojson`)).json()).features[0]?.geometry?.coordinates

  const enderecoDestino = cota.NM_LGDDST + ', ' + cota.NR_LGDDST + ', ' + cota.NM_LOCDST + ',' + cota.ID_UFDST + ',' + cota.NM_PAISDST
  const enderecoDestinoSemLGD = cota.NM_LOCDST + ',' + cota.ID_UFDST + ',' + cota.NM_PAISDST
  const coordDestino =
    (await (await fetch(`https://nominatim.openstreetmap.org/search?q=${enderecoDestino}&format=geojson`)).json()).features[0]?.geometry?.coordinates
    ?? (await (await fetch(`https://nominatim.openstreetmap.org/search?q=${enderecoDestinoSemLGD}&format=geojson`)).json()).features[0]?.geometry?.coordinates

    const response = await Promise.all ([
      App.api('parceiros::consulta', { nr_cpf: cnpjTrp }),
      App.api('parceiros::consulta', { nr_cpf: cota.NR_CNPJUND }),
      App.api('parceiros::consulta', { nr_cpf: cota.NR_CNPJORI }),
      App.api('parceiros::consulta', { nr_cpf: cota.NR_CNPJDST }),
      App.api('parceiros::consulta', { nr_cpf: cota.NR_CNPJPRC }),
      App.api('portarias::get', {id_uocc: prtIdUocc}),
    ])

    const [
      { results: dataTrp },
      { results: dataUnd },
      { results: dataOri },
      { results: dataDst },
      { results: dataCli },
      { results: [dataAgendamento] },
    ] = response


  console.log (cota, motorista, tracao, prtIdUocc, cnpjTrp, App, dataAgendamento )
  const obj = {
    nif: App.user.cliente + prtIdUocc,
    notificar: [{
      tipo: "PUSHNOTIFICATION",
      titulo: "Agendamento",
      mensagem: `Novo agendamento registrado com sucesso na portaria ${prtIdUocc}!`
    }],
    portarias: [{
      nif_agendamento: prtIdUocc,
      turno: cota.DS_TRN,
      data: cota.DT_COTAORI,
      produto: cota.NM_PRO,
      operacao: cota.DS_OPRPRT,
      situacao: 1,
      nif_motorista: motorista.doc_motorista,
      nif_transportadora: sessionStorage.getItem('nr_cnpj'),
      nif_organizacao: App.user.cliente,
      nif_origem: cota.NR_CNPJORI,
      nif_destino: cota.NR_CNPJDST,
      nif_cliente: cota.NR_CNPJPRC,
      veiculos: [{
        placa: tracao.id_vcl,
        renavam: tracao.nr_renavam,
        tipo: "TRACAO",
        marca: tracao.DS_MARCA,
        modelo: tracao.DS_MODELO,
        ano_fabricacao: tracao.DT_ANOFAB,
        ano_modelo: tracao.DT_ANOMOD,
        uf_emplacamento: tracao.ID_UF,
        km_litro: "",
        qtd_eixos: tracao.QT_EIXO
      }],
      motorista: {
        nif: motorista.doc_motorista,
        nome: motorista.nm_motorista,
        nome_fantasia: motorista.nm_motorista,
        telefone_fixo: "",
        celular_1: motorista.nr_telcel,
        celular_2: "",
        celular_whatsapp: motorista.nr_telcel,
        controla_aproximacao: "NAO",
        controla_aproximacao_metros: 0,
        logradouro: "",
        numero: "",
        complemento: "",
        estado: "",
        uf: "",
        cep: "",
        pais: ""
      },
      transportadora: {
        nif: cnpjTrp,
        nome: dataTrp.nm_pes,
        nome_fantasia: dataTrp.nm_fantasia,
        logradouro: dataTrp.nm_lgd,
        numero: dataTrp.nr_lgd,
        bairro: dataTrp.nm_bro,
        complemento: dataTrp.nm_cmpto,
        estado: dataTrp.id_uf,
        cidade: dataTrp.nm_loc,
        uf: dataTrp.nm_uf,
        cep: dataTrp.cd_cep,
        pais: dataTrp.nm_pais,
        longitude: "",
        latitude: "",
        telefone: dataTrp.nr_telcel,
        celular: "",
        whatsapp: "",
        controla_aproximacao: "NAO",
        controla_aproximacao_metros: "0",
        avatar: null
      },
      organizacao: {
        nif: App.user.cliente,
        nome: dataUnd.nm_pes,
        nome_fantasia: dataUnd.nm_fantasia,
        logradouro: dataUnd.nm_lgd,
        numero: dataUnd.nr_lgd,
        bairro: dataUnd.nm_bro,
        complemento: dataUnd.nm_cmpto,
        estado: dataUnd.nm_uf,
        cidade: dataUnd.nm_loc,
        uf: dataUnd.id_uf,
        cep: dataUnd.cd_cep,
        pais: dataUnd.nm_pais,
        longitude: "",
        latitude: "",
        telefone: "",
        celular: dataUnd.nr_telcel,
        whatsapp: "",
        controla_aproximacao: "NAO",
        controla_aproximacao_metros: "0",
        avatar: null
      },
      origem: {
        nif: cota.NR_CNPJORI,
        nome: dataOri.nm_pes,
        nome_fantasia: dataOri.nm_fantasia,
        logradouro: dataOri.nm_lgd,
        numero: dataOri.nr_lgd,
        bairro: dataOri.nm_bro,
        complemento: dataOri.nm_cmpto,
        estado: dataOri.nm_uf,
        cidade: dataOri.nm_loc,
        uf: dataOri.id_uf,
        cep: dataOri.cd_cep,
        pais: dataOri.nm_pais,
        longitude: coordOrigem[0],
        latitude: coordOrigem[1],
        telefone: dataOri.nr_telcel,
        celular: "",
        whatsapp: "",
        controla_aproximacao: "SIM",
        controla_aproximacao_metros: "500",
        avatar: null
      },
      destino: {
        nif: cota.NR_CNPJDST,
        nome: dataDst.nm_pes,
        nome_fantasia: dataDst.nm_fantasia,
        logradouro: dataDst.nm_lgd,
        numero: dataDst.nr_lgd,
        bairro: dataDst.nm_bro,
        complemento: dataDst.nm_cmpto,
        estado: dataDst.nm_uf,
        cidade: dataDst.nm_loc,
        uf: dataDst.id_uf,
        cep: dataDst.cd_cep,
        pais: dataDst.nm_pais,
        longitude: coordDestino[0],
        latitude: coordDestino[1],
        telefone: dataDst.nr_telcel,
        celular: "",
        whatsapp: "",
        controla_aproximacao: "SIM",
        controla_aproximacao_metros: "500",
        avatar: null
      },
      cliente: {
        nif: cota.NR_CNPJPRC,
        nome: dataCli.nm_pes,
        nome_fantasia: dataCli.nm_fantasia,
        logradouro: dataCli.nm_lgd,
        numero: dataCli.nr_lgd,
        bairro: dataCli.nm_bro,
        complemento: dataCli.nm_cmpto,
        estado: dataCli.nm_uf,
        cidade: dataCli.nm_loc,
        uf: dataCli.id_uf,
        cep: dataCli.cd_cep,
        pais: dataCli.nm_pais,
        longitude: "",
        latitude: "",
        telefone: dataCli.nr_telcel,
        celular: "",
        whatsapp: "",
        controla_aproximacao: "NAO",
        controla_aproximacao_metros: "0",
        avatar: null
      }
    }],
    checkpoint: [
      {
        "programado": {
          "titulo": "Programado",
          "dataHoraInicio": dataAgendamento.DT_OPR,
          "dataHoraFim": "",
          "em_progresso": {
            "descricao": [
              "Em Progresso"
            ],
            "infos": [
              "Confirme o recebimento do agendamento!"
            ],
            "icon": "clock-time-three-outline",
            "auto": false,
            "status": false
          },
          "concluido": {
            "descricao": [
              "Finalizado"
            ],
            "infos": [
              "Você confirmou o recebimento!"
            ],
            "icon": "timer-sand",
            "auto": true,
            "status": true
          }
        }
      },
      {
        "iniciado": {
          "titulo": "Iniciado",
          "dataHoraInicio": "",
          "dataHoraFim": "",
          "pendente": {
            "descricao": [
              "Não iniciado"
            ],
            "infos": [
              "Em breve você poderá iniciar a viagem. Você esta a <distancia_gps> km do destino!"
            ],
            "icon": "map-marker-alert-outline",
            "auto": true,
            "status": false
          },
          "em_progresso": {
            "descricao": [
              "Em Progresso"
            ],
            "infos": [
              "Confirme o inicio da viagem, ou movimente o seu veículo!"
            ],
            "icon": "calendar-clock",
            "auto": false,
            "status": false
          },
          "concluido": {
            "descricao": [
              "Finalizado"
            ],
            "infos": [
              "Viagem iniciada!"
            ],
            "icon": "truck-fast-outline",
            "auto": true,
            "status": true
          }
        }
      },
      {
        "chegada_origem": {
          "titulo": "Chegada Origem",
          "dataHoraInicio": "",
          "dataHoraFim": "",
          "pendente": {
            "descricao": [
              "Não iniciado"
            ],
            "icon": "map-marker-alert-outline",
            "auto": true,
            "status": false
          },
          "em_progresso": {
            "descricao": [
              "A caminho do endereço:"
            ],
            "infos": [
              "Siga para <endereco_origem>"
            ],
            "icon": "map-marker-radius-outline",
            "auto": false,
            "status": false,
            "raio_metros": 1000
          },
          "concluido": {
            "descricao": [
              "Você fez check-in no endereço de origem"
            ],
            "icon": "map-marker-check-outline",
            "auto": true,
            "status": true
          }
        }
      },
      {
        "nfe_cte": {
          "titulo": "NFe / CTe",
          "dataHoraInicio": "",
          "dataHoraFim": "",
          "pendente": {
            "descricao": [
              "Não iniciado"
            ],
            "icon": "map-marker-alert-outline",
            "auto": true,
            "status": false
          },
          "em_progresso": {
            "descricao": [
              "Em Progresso"
            ],
            "icon": "file-cloud-outline",
            "auto": true,
            "status": false,
            "nfe_obrigatorio": true,
            "cte_obrigatorio": true,
            "nfe_base64": "",
            "cte_base64": ""
          },
          "concluido": {
            "descricao": [
              "Finalizado"
            ],
            "icon": "file-check-outline",
            "auto": true,
            "status": true
          }
        }
      },
      {
        "saida_origem": {
          "titulo": "Saída Origem",
          "dataHoraInicio": "",
          "dataHoraFim": "",
          "pendente": {
            "descricao": [
              "Não iniciado"
            ],
            "icon": "map-marker-alert-outline",
            "auto": true,
            "status": false
          },
          "em_progresso": {
            "descricao": [
              "Em Progresso"
            ],
            "icon": "map-marker-radius-outline",
            "auto": false,
            "status": false,
            "raio_metros": 1000
          },
          "concluido": {
            "descricao": [
              "Você fez check-out no endereço de origem"
            ],
            "icon": "map-marker-check-outline",
            "auto": true,
            "status": true
          }
        }
      },
      {
        "chegada_destino": {
          "titulo": "Chegada Destino",
          "dataHoraInicio": "",
          "dataHoraFim": "",
          "pendente": {
            "descricao": [
              "Não iniciado"
            ],
            "icon": "map-marker-alert-outline",
            "auto": true,
            "status": false
          },
          "em_progresso": {
            "descricao": [
              "A caminho do endereço:"
            ],
            "infos": [
              "Siga para <endereco_destino>"
            ],
            "icon": "map-marker-radius-outline",
            "auto": false,
            "status": false,
            "raio_metros": 1000
          },
          "concluido": {
            "descricao": [
              "Você fez check-in no endereço de destino"
            ],
            "icon": "map-marker-check-outline",
            "auto": true,
            "status": true
          }
        }
      },
      {
        "ticket_canhoto": {
          "titulo": "Ticket / Canhoto",
          "dataHoraInicio": "",
          "dataHoraFim": "",
          "pendente": {
            "descricao": [
              "Não iniciado"
            ],
            "icon": "map-marker-alert-outline",
            "auto": true,
            "status": false
          },
          "em_progresso": {
            "descricao": [
              "Em Progresso"
            ],
            "icon": "file-cloud-outline",
            "auto": true,
            "status": false,
            "ticket_obrigatorio": true,
            "canhoto_obrigatorio": true,
            "ticket_base64": "",
            "canhoto_base64": ""
          },
          "concluido": {
            "descricao": [
              "Finalizado"
            ],
            "icon": "file-check-outline",
            "auto": true,
            "status": true
          }
        }
      },
      {
        "saida_destino": {
          "titulo": "Saída Destino",
          "dataHoraInicio": "",
          "dataHoraFim": "",
          "pendente": {
            "descricao": [
              "Não iniciado"
            ],
            "icon": "map-marker-alert-outline",
            "auto": true,
            "status": false
          },
          "em_progresso": {
            "descricao": [
              "Em Progresso"
            ],
            "icon": "map-marker-radius-outline",
            "auto": false,
            "status": false,
            "raio_metros": 1000
          },
          "concluido": {
            "descricao": [
              "Você fez check-out no endereço de destino"
            ],
            "icon": "map-marker-check-outline",
            "auto": true,
            "status": true
          }
        }
      },
      {
        "concluido": {
          "titulo": "Concluído",
          "dataHoraInicio": "",
          "dataHoraFim": "",
          "pendente": {
            "descricao": [
              "Não iniciado"
            ],
            "icon": "map-marker-alert-outline",
            "auto": true,
            "status": false
          },
          "concluido": {
            "descricao": [
              "Agendamento Finalizado"
            ],
            "icon": "truck-fast-outline",
            "auto": true,
            "status": true
          }
        }
      }
    ]
  }

  fetch('https://velog.vertti.com.br/api/integracao-velog/' + App.user.cliente + '/novo-agendamento', {
    method: 'POST',
    headers: {
      "Authorization": `Bearer VfCmfpHxybAJ1JX29SjTy4KbiI95i39T1Aak9rAyUxPzfFoHWUj2yy2uMrlB3Ff8`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ ...obj })
  })
  .then(response => {
    if (!response.ok) {
        throw new Error(`Erro: ${response.status}`);
    }
    return response.json();
  })
  .then(data => {
      console.log("Resposta da API:", data);
  })
  .catch(error => {
      console.error("Erro na requisição:", error);
  });
}

/*
A funcao deve validar as quantiadades de itens da OC, os produtos
1- Verificar se excede o numero disponel nos clientes qnt > limite?
2- Nao permitir numeros negativos
*/
export function validateOCItem(itens) {
  var valido = true;
  itens.forEach(item => {
    let max = parseInt(item.QT_EXPA) + parseInt(item.RESTANTES)
    if (parseInt(item.QT_EXP) > max || parseInt(item.QT_EXP) < 0) {
      valido = false
    }
  })
  return valido;
}

export async function sendAppApiDeletePortaria({ prtIdUocc, App }) {
  fetch('https://velog.vertti.com.br/api/agendamento-portal/' + App.user.cliente + '/' + prtIdUocc, {
    method: 'DELETE',
    headers: { 'content-type': 'application/json' },
  })
}

export async function resizeImg(file, width, height) {
  return new Promise((resolve) => {
    try {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        file.type.split('/')[1].toUpperCase(),
        75,
        0,
        (img) => resolve(img),
      );
    } catch (err) {
      console.log(err);
    }
  })
}

export function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}
export const validarCRLV = async ({ tpExt, dsDoc, App, setLoading }) => {

  if(dsDoc.length < 100){
    return false;
  }

  try {
    setLoading(true);

    const response = await App.api('veiculos::validarCRLV', {
      tp_ext: tpExt,
      ds_doc: dsDoc,
    });

    const renavam = response.results.find(item => item.type === 'CLRV_RENAVAM')?.text || '';
    const placa = response.results.find(item => item.type === 'CLRV_PLACA')?.text || '';
    const anoFab = Number(response.results.find(item => item.type === 'CLRV_ANO')?.text || '');
    const modelo = response.results.find(item => item.type === 'CLRV_MODELO')?.text || '';
    const marca = response.results.find(item => item.type === 'CLRV_MARCA')?.text || '';
    const uf = response.results.find(item => item.type === 'CLRV_LOCAL')?.text || '';
    const pesoBruto = Number(response.results.find(item => item.type === 'CRLV_PESO')?.text || '');
    const capacidade = Number(response.results.find(item => item.type === 'CLRV_CAPACIDADE')?.text || '');
    const eixos = Number(response.results.find(item => item.type === 'CRLV_EIXOS')?.text || '');
    const anoModelo = Number(response.results.find(item => item.type === 'CLRV_ANO_MODELO')?.text || '');
    const assertividade = response.results.find(item => item.type === 'ASSERTIVIDADE')?.text || -1;

    setLoading(false);

    if (assertividade > 69.9) {
      App.toast.success('CRLV validado com sucesso! Verifique se todos os campos foram preenchidos corretamente. Caso contrário, favor atualizar manualmente!');

      return {
        placa, modelo, marca, renavam, capacidade, eixos, qt_comp: '', pesoBruto, uf, anoFab, anoModelo, pais: 'Brasil',
        status:true
      };
    } else {

      if(assertividade >=-1)
        App.toast.error('Erro ao validar o CRLV. Por favor, tente novamente ou insira os dados manualmente.');

      return {
        placa: '', modelo: '', marca: '', renavam: '', capacidade: '', eixos: '', qt_comp: '', pesoBruto: '', uf: '', anoFab: '', anoModelo: '', pais: 'Brasil',
        status:false
      };
    }
  } catch (error) {
    setLoading(false);
    console.error('Erro ao validar o CRLV:', error);
    throw error;
  }
};

export const validarCNH = async ({ tpExt, dsDoc, App, setLoading }) => {

  if(dsDoc.length < 100){
    return false;
  }

  try {
    setLoading(true);

    const response = await App.api('parceiros::validarCNH', {
      tp_ext: tpExt,
      ds_doc: dsDoc,
    });

    const nomeCompleto = response.results.find(item => item.type === 'NOME_MOTORISTA')?.text || '';
    const registro_cnh = response.results.find(item => item.type === 'REGISTRO_CNH')?.text || '';
    const categoria = response.results.find(item => item.type === 'CATEGORIA_CNH')?.text || '';
    const cpf = response.results.find(item => item.type === 'CPF_CNH')?.text || '';
    const rg = (response.results.find(item => item.type === 'RG')?.text || '').match(/\d+/g).join('');
    const emissor = response.results.find(item => item.type === 'EMISSOR_CNH')?.text || '';
    const emissor_uf = response.results.find(item => item.type === 'EMISSOR_CNH_UF')?.text || '';
    let data_nascimento = response.results.find(item => item.type === 'DATA_NASCIMENTO_CNH')?.text || '';
    let validade = response.results.find(item => item.type === 'VALIDADE_CNH')?.text || '';
    let data_emissao = response.results.find(item => item.type === 'DATA_EMISSAO')?.text || '';
    const assertividade = response.results.find(item => item.type === 'ASSERTIVIDADE')?.text || -1;

    if (data_nascimento) {
      const [dia, mes, ano] = data_nascimento.split('/');
      data_nascimento = `${ano}-${mes}-${dia}`;
    }

    if (validade) {
      const [dia, mes, ano] = validade.split('/');
      validade = `${ano}-${mes}-${dia}`;
    }

    if (data_emissao) {
      const [dia, mes, ano] = data_emissao.split('/');
      data_emissao = `${ano}-${mes}-${dia}`;
    }

    setLoading(false);

    if (assertividade > 69.9) {
      App.toast.success('CNH validada com sucesso! Verifique se todos os campos foram preenchidos corretamente. Caso contrário, favor atualizar manualmente!');

      return {
        nomeCompleto, cpf, rg, validade, emissor, emissor_uf, data_nascimento, registro_cnh, categoria, data_emissao,
        status:true
      };
    } else {

      if(assertividade >=-1)
        App.toast.error('Erro ao validar a CNH. Por favor, tente novamente ou insira os dados manualmente.');

      return {
        nomeCompleto:'', categoria: '', cpf:'', rg:'', validade:'', emissor:'', emissor_uf:'', data_nascimento:'', registro_cnh: '', data_emissao: '',
        status:false
      };
    }
  } catch (error) {
    setLoading(false);
    console.error('Erro ao validar a CNH:', error);
    throw error;
  }
};

const translate_counter = (lang, count) => {
  if(count === 0) return (lang.zero ?? lang.others).replace('%{count}', count)

  if(count === 1) return lang.one

  return lang.others.replace('%{count}', count)
}

export const translate = (lang, target, options = {}) => {
  let message = lang[target] ?? options.defaultValue ?? target

  // Se a opção count existir, usa translate_counter
  if (options.count !== undefined) {
    message = translate_counter(message, options.count);
  }

   // Substituir todos os placeholders por valores das opções
  const translation = Object.entries(options).reduce((text, [key, value]) => {
    const parsedValue = Array.isArray(value) ? value.join(', ') : value;

    // Usa regex para garantir que todas as ocorrências de %{key} sejam substituídas
    const regex = new RegExp(`%{${key}}`, 'g');
    return text.replace(regex, parsedValue);
  }, message);

  return translation
}

export const t = translate

export function calculatePercentage(numerator, denominator) {
  if (!numerator || !denominator) return 0;
  return ((numerator / denominator) * 100).toFixed(2);
}

export const decodeUtf8EncodedString = (text) => {
  try {
    return decodeURIComponent(escape(text));
  } catch (e) {
    return text;
  }
};
